<template>
  <div>
    <v-card>
      <v-toolbar flat color="white">
        <v-toolbar-title>
          <v-icon class="mb-1">mdi-account-alert</v-icon>
          NOTIFIES
        </v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          single-line
          class="mt-5"
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-checkbox
          v-model="incluirInactivos"
          label="Incluir inactivos"
          @change="cargarLista"
        ></v-checkbox>
        <v-spacer></v-spacer>
        <v-btn small color="primary" @click="abrirDialogNotify(null)">
          <v-icon>mdi-plus-circle-outline</v-icon> Nuevo notify
        </v-btn>
      </v-toolbar>
      <v-data-table
        :headers="headers"
        :items="lista"
        :items-per-page="itemsPerPage"
        :search="search"
        :loading="loadingTable"
        class="elevation-1"
      >
        <template v-slot:item.opcion="{ item }">
          <v-btn icon color="green" @click="abrirDialogNotify(item.NOTIFY_ID)">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>

          <v-btn icon color="red" @click="eliminarNotify(item.NOTIFY_ID)">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>

        <template v-slot:item.ACTIVO="{ item }">
          {{ item.ACTIVO == "1" ? "ACTIVO" : "INACTIVO" }}
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="dialogNotify" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <v-icon class="mb-1">mdi-account-alert</v-icon>
          <span class="headline">Notify | {{ datosNotify.NOTIFY_ID }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form ref="formNotify">
              <v-row>
                <v-col class="pt-0 pb-0" cols="12">
                  <v-text-field
                    :rules="requiredRule"
                    v-model="datosNotify.NOTIFY_NOMBRE"
                    label="Nombre "
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="pt-0 pb-0" cols="6">
                  <v-select
                    clearable
                    :items="tiposIdent"
                    item-text="DESCRIPCION"
                    item-value="ID"
                    v-model="datosNotify.TIPO_IDENT"
                    label="Tipo de identificación"
                  ></v-select>
                </v-col>
                <v-col class="pt-0 pb-0" cols="6">
                  <v-text-field v-model="datosNotify.NOTIFY_RUC" label="Ruc">
                  </v-text-field>
                </v-col>

                <v-col class="pt-0 pb-0" cols="6">
                  <v-text-field
                    v-model="datosNotify.NOTIFY_TELEFONO"
                    label="Teléfono"
                  >
                  </v-text-field>
                </v-col>
                <v-col class="pt-0 pb-0" cols="6">
                  <v-text-field
                    v-model="datosNotify.NOTIFY_CELULAR"
                    label="Celular"
                  >
                  </v-text-field>
                </v-col>
                <v-col class="pt-0 pb-0" cols="6">
                  <v-text-field
                    :rules="validarEmail1"
                    v-model="datosNotify.NOTIFY_EMAIL"
                    label="Email principal"
                  >
                  </v-text-field>
                </v-col>
                <v-col class="pt-0 pb-0" cols="6">
                  <v-text-field
                    :rules="validarEmail2"
                    v-model="datosNotify.NOTIFY_EMAIL2"
                    label="Email secundario"
                  >
                  </v-text-field>
                </v-col>
                <v-col class="pt-0 pb-0" cols="12">
                  <v-text-field
                    v-model="datosNotify.NOTIFY_DIRECCION"
                    label="Dirección"
                  >
                  </v-text-field>
                </v-col>
                <v-col class="pt-0 pb-0" cols="12">
                  <v-autocomplete
                    :rules="requiredRule"
                    :items="paises"
                    item-text="NOMBRE"
                    item-value="PAIS_CODIGO"
                    v-model="datosNotify.PAIS_COD"
                    label="Pais"
                    @change="cambiarCodigoPais"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-col class="text-center">
            <v-btn small color="primary" @click="guardarNotify">
              <v-icon>mdi-content-save</v-icon> Guardar
            </v-btn>
            &nbsp;&nbsp;
            <v-btn small @click="dialogNotify = !dialogNotify">
              <v-icon>mdi-cancel</v-icon> Cancelar
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import Vue from "vue";

export default {
  name: "NotifyComponent",
  data: () => ({
    headers: [
      { text: "Opciones", value: "opcion" },
      { text: "Codigo", value: "NOTIFY_ID" },
      { text: "Nombre", value: "NOTIFY_NOMBRE" },
      { text: "Activo", value: "ACTIVO" },
    ],

    requiredRule: [(v) => !!v || "El campo es requerido"],
    emailRules: [
      (v) =>
        String(v)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ) || "Ingrese un Email válido",
    ],
    lista: [],
    options: {},
    dialog: false,
    notifications: false,
    sound: true,
    widgets: true,

    search: null,
    incluirInactivos: false,

    dialogNotify: false,
    datosNotify: {
      NOTIFY_ID: "",
      NOTIFY_NOMBRE: "",
      NOTIFY_DIRECCION: "",
      NOTIFY_TELEFONO: "",
      NOTIFY_ESTADO: "",
      NOTIFY_EMAIL2: "",
      NOTIFY_EMAIL: "",
      TIPO_IDENT: 0,
      NOTIFY_RUC: "",
      PAIS_COD: "",
      ACTIVO: 1,
    },

    tiposIdent: [],
    paises: [],
    itemsPerPage: 20,
    creaNtfy: true,
  }),
  computed: {
    ...mapState("master", ["loadingTable", "user"]),
    ...mapGetters("access", [""]),
    validarEmail1() {
      return this.validarEmail(this.datosNotify.NOTIFY_EMAIL);
    },
    validarEmail2() {
      return this.validarEmail(this.datosNotify.NOTIFY_EMAIL2);
    },
  },
  methods: {
    ...mapMutations("master", [
      "setUrl",
      "setOverlay",
      "setMenu",
      "setLoadingTable",
      "setTitleToolbar",
    ]),

    ...mapActions("master", ["requestApi", "alertNotification"]),

    validarEmail(email) {
      if (email == "") {
        return [true];
      }
      if (email == null) {
        return [true];
      }
      if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
        return ["Email inválido"];
      } else {
        return [true];
      }
    },

    cargarLista() {
      this.setLoadingTable(true);
      this.setUrl("api/lista-notifies");
      this.requestApi({
        method: "GET",
        data: {
          incluirInactivos: this.incluirInactivos,
        },
      })
        .then((res) => {
          //console.log(res);
          this.lista = res.data;
          this.pageCount = 10;
        })
        .catch(() => {})
        .then(() => {
          this.setLoadingTable(false);
        });
    },

    modificarNotify() {
      this.setUrl("api/modificar-notify");
      this.requestApi({
        method: "POST",
        data: {
          notify: this.datosNotify,
        },
      })
        .then((res) => {
          this.alertNotification({ param: { html: res.data.msg } });
          this.cargarLista();
          this.dialogNotify = false;
        })
        .then(() => {});
    },

    abrirDialogNotify(notifyid) {
      this.creaNtfy = false;
      if (notifyid == null) {
        this.datosNotify = {
          NOTIFY_ID: "",
          NOTIFY_NOMBRE: "",
          NOTIFY_DIRECCION: "",
          NOTIFY_TELEFONO: "",
          PAIS_COD: "",
          TIPO_IDENT: 0,
          ACTIVO: 1,
        };
        this.creaNtfy = true;
      }
      this.setUrl("api/notify");
      this.requestApi({
        method: "GET",
        data: {
          NOTIFY_ID: notifyid,
        },
      })
        .then((res) => {
          if (res.data.notify != null) {
            this.datosNotify = res.data.notify;
          }
          this.paises = res.data.pais;
          this.tiposIdent = res.data.tipo_ident;
          this.dialogNotify = true;
        })
        .then(() => {});
    },

    eliminarNotify(notifyId) {
      Vue.swal({
        html: "Está seguro de eliminar el notify ?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cerrar",
        ...this.paramAlertQuestion,
      }).then((result) => {
        if (result.isConfirmed) {
          this.setUrl("api/borrar-notify");
          this.requestApi({
            method: "POST",
            data: {
              NOTIFY_ID: notifyId,
            },
          }).then((res) => {
            //console.log(res);
            this.cargarLista();
            this.alertNotification({ param: { html: res.data.msg } });
          });
        }
      });
    },
    guardarNotify() {
      if (!this.$refs.formNotify.validate()) {
        return false;
      }
      if (this.creaNtfy == true) {
        this.crearNotify();
      } else {
        this.modificarNotify();
      }
    },
    crearNotify() {
      console.log(this.datosNotify);
      this.setUrl("api/crear-notify");
      this.requestApi({
        method: "POST",
        data: {
          notify: this.datosNotify,
        },
      })
        .then((res) => {
          this.alertNotification({ param: { html: res.data.msg } });
          this.dialogNotify = false;
          this.cargarLista();
        })
        .then(() => {});
    },
    cambiarCodigoPais() {
      //this.datosNotify.SHP_PAIS = this.datosNotify.PAIS_COD;
    },
  },

  mounted() {
    document.title = "Sistema de carga";
    this.setTitleToolbar("Notifies");
    this.cargarLista();
  },
};
</script>
